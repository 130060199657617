import React, { useState } from "react";
import "./Saving.css";
import { useInput } from "../../hooks/input-hook";
import { withRouter } from "react-router-dom";
import StandardLogo from "../../assets/StandardLogo.png";
import axios from "axios";
import CurrencyFormat from "react-currency-format";
import { ExternalScreenStateContext } from "../contexts";
import moment from "moment";

const LoanItem = (props) => {
  return (
    <div className="loan-box">
      <div className="form-inline">
        <div className="form-group loanprinciple-box">
          <label>Loan Amount (Principal)</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">$</div>
            </div>
            <input
              type="text"
              placeholder={props.principal}
              className="form-control"
              onChange={props.handleChange("principal")}
            />
          </div>
        </div>
        <div className="form-group rate-box">
          <label>Int. Rate</label>
          <div className="input-group">
            <input
              type="text"
              placeholder={props.interest_rate}
              className="form-control"
              onChange={props.handleChange("interest_rate")}
            />
            <div className="input-group-prepend">
              <div className="input-group-text">%</div>
            </div>
          </div>
        </div>
        <div className="form-group minimum-box">
          <label>Monthly Minimum Payment</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">$</div>
            </div>
            <input
              type="text"
              placeholder={props.payment}
              className="form-control"
              onChange={props.handleChange("payment")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Home = (props) => {
  const [screenIndex, setScreenIndex] = useState(0);
  const [savingsEstimateData, setSavingsEstimateData] = useState({});
  const [smsEligible, setSmsEligible] = useState(false);

  const { value: agi, bind: bindAgi, reset: resetAgi } = useInput(0);
  const { value: email, bind: bindEmail, reset: resetEmail } = useInput("");
  const {
    value: phoneNumber,
    bind: bindPhoneNumber,
    reset: resetPhoneNumber,
  } = useInput("");
  const [pslf, setPslf] = useState(false);
  const [householdSize, setHouseholdSize] = useState(1);
  const [place, setPlace] = useState("CONTIGUOUS_US");
  const calculatorInputs = {
    agi: useInput(0),
    funds: useInput(0),
    location: useInput(""),
    householdSize: useInput(0),
    isEmployedByNonprofit: useInput(false),
    monthsForbearanceUsed: useInput(0),
  };
  const [loans, setLoans] = useState([
    generateNewLoanRowData(true), // one public loan
  ]);

  function handleAddFederalLoan(e) {
    e.preventDefault();
    setLoans([...loans, generateNewLoanRowData(true)]);
  }

  function handleAddPrivateLoan(e) {
    e.preventDefault();
    setLoans([...loans, generateNewLoanRowData(false)]);
  }

  function generateNewLoanRowData(federal) {
    // loans useState is defined using this, so loans might
    // be undefined the first time it's used.
    let len = 0;
    if (typeof loans !== "undefined") {
      len = loans.length;
    }
    let newLoan = {
      idx: len,
      principal: 0,
      interest_rate: 0,
      payment: 0,
      federal: federal, // whether it's a federal loan.
    };
    return newLoan;
  }

  // TODO: This is gross. I want to bind the React hook state to individual
  // input boxes, but I can't add a new useState/useInput in the onClick, so
  // I can't get the "bind" behavior from useInput that I want. Therefore I
  // have to manually watch each box for changes and update as appropriate.
  // PLEASE NOTE: This is very very to breakage if rowData field names change.
  // 1) Bind to idx
  const handleLoanDataChange = (idx) => {
    // Bind to fieldName
    return (fieldName) => {
      // The actual function, bound to idx and fieldName
      return (evt) => {
        const updatedLoansData = loans.map((rowData, loan_idx) => {
          if (idx !== loan_idx) return rowData; // don't touch other rows
          rowData[fieldName] = evt.target.value;
          return rowData;
        });
        setLoans(updatedLoansData);
      };
    };
  };
  /*

    let newLoan = {
      idx: len,
      principal: 0,
      interest_rate: 0,
      payment: 0,
      federal: federal  // whether it's a federal loan.
    }
*/

  function calcFunds(income) {
    return income / 12.0;
  }

  function convertToLoanJson(loans) {
    let rekeyed_data = [];
    for (const loan of loans) {
      rekeyed_data.push({
        principal: Number(loan.principal),
        rate: Number(loan.interest_rate),
        min_payment: Number(loan.payment),
        is_federal: Boolean(loan.federal),
      });
    }
    return JSON.stringify(rekeyed_data);
  }

  async function handleSubmit(evt) {
    evt.preventDefault();
    await axios
      .get("/xapi/sms_eligibility")
      .catch((err) => {
        alert("BAD!");
        console.log(err);
      })
      .then((resp) => {
        setSmsEligible(resp.data.eligible);
      });

    await axios
      .post(
        "/xapi/calculate",
        {},
        {
          data: {
            agi: Number(agi),
            location: place,
            funds: calcFunds(Number(agi)),
            household_size: householdSize,
            pslf: pslf,
            forbearance: 0,
            loan: convertToLoanJson(loans),
          },
        }
      )
      .catch((err) => {
        alert("BAD!");
        console.table(err);
      })
      .then((resp) => {
        if (resp.data.code === "NOT_POSSIBLE") {
          alert("Data invalid, try again.");
        } else {
          console.table(resp);
          setSavingsEstimateData(resp.data.data);
          setScreenIndex(2);
        }
      });
  }

  async function handleSMSRegisterSubmit(evt) {
    evt.preventDefault();

    await axios
      .post(
        "/sms/register",
        {},
        {
          data: {
            agi: Number(agi),
            location: place,
            household_size: householdSize,
            phone_number: phoneNumber,
            pslf: pslf,
            forbearance: 0,
            loan: convertToLoanJson(loans),
          },
        }
      )
      .catch((err) => {
        alert("BAD!");
        console.table(err);
      })
      .then((resp) => {
        console.log(resp);
        setScreenIndex(4);
      });
  }

  async function handleEmailStrategyRequestSubmit(evt) {
    console.log("Fake for now!");
  }

  function renderLoanRow(rowData) {
    return (
      <LoanItem
        principal={rowData.principal}
        interest_rate={rowData.interest_rate}
        payment={rowData.payment}
        handleChange={handleLoanDataChange(rowData.idx)}
      />
    );
  }

  function getLoanTotal() {
    return loans
      .map((loan) => Number(loan.principal))
      .reduce((total, cur) => total + cur);
  }

  function handleNavigateToIndex(index) {
    return (evt) => {
      evt.preventDefault();
      setScreenIndex(index);
    };
  }

  function generateCalculatorInputBox() {
    switch (screenIndex) {
      case 0:
        return generateLoanInputs();
      case 1:
        return generateMetadataInputs();
      case 2:
        return generateResultScreen();
      case 3:
        return generateThankYouForEmailRequest();
      case 4:
        return generateThankYouForSMSRequest();
    }
  }

  function generateThankYouForEmailRequest() {
    return (
      <div>
        <div className="logo-container">
          <img src={StandardLogo} alt="colored logo img" />
        </div>
        <div className="text-center">
          <h4>You are now subscribed for email updates from Anvil!</h4>
        </div>
      </div>
    );
  }

  function generateThankYouForSMSRequest() {
    return (
      <div>
        <div className="logo-container">
          <img src={StandardLogo} alt="colored logo img" />
        </div>
        <div className="text-center">
          <h4>
            Welcome to the Anvil SMS Beta program! You will begin to receive
            texts from us shortly.
          </h4>
        </div>
      </div>
    );
  }

  function generateMetadataInputs() {
    let pslfSelectData = [
      { label: "No", value: "false" },
      { label: "Yes", value: "true" },
    ];
    let householdRange = [];
    for (let i = 0; i < 12; i++) {
      householdRange.push(i);
    }

    return (
      <div>
        <h3>Just a little bit more information...</h3>
        <div className="loan-box">
          <div className="form-inline my-estimate-form-inline">
            <div className="form-group my-estimate-box">
              <div>
                <label>What is your gross annual income?</label>
                <div className="input-group">
                  <input type="number" {...bindAgi} className="form-control" />
                </div>
              </div>
              <div>
                <label> What part of the country do you live in? </label>
                <div className="input-group">
                  <select
                    id="cars"
                    name="cars"
                    className="form-control"
                    onChange={(e) => setPlace(e.currentTarget.value)}
                  >
                    <option value="CONTIGUOUS_US">Contiguous US</option>
                    <option value="HAWAII">Hawaii</option>
                    <option value="ALASKA">Alaska</option>
                  </select>
                </div>
              </div>
              <div>
                <label>How many dependents do you have?</label>
                <div className="input-group">
                  <select
                    id="householdSize"
                    name="householdSize"
                    className="form-control"
                    onChange={(e) =>
                      setHouseholdSize(Number(e.currentTarget.value) + 1)
                    }
                  >
                    {householdRange.map((val) => (
                      <option value={val}>{val}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                <label>Do you work for a government entity or nonprofit?</label>
                <div className="input-group">
                  <select
                    id="pslf"
                    name="pslf"
                    className="form-control"
                    onChange={(e) => setPslf(e.currentTarget.value)}
                  >
                    {pslfSelectData.map((item) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-estimate-box-button-container">
          <button
            onClick={(evt) => {
              evt.preventDefault();
              setScreenIndex(0);
            }}
            className="btn btn-primary mx-auto"
          >
            Change Loans
          </button>
          <button type="submit" className="btn btn-primary mx-auto">
            Get My Estimate
          </button>
        </div>
      </div>
    );
  }

  function generateLoanInputs() {
    let privLoanArea;
    if (loans.filter((rowData) => rowData.federal === false).length === 0) {
      privLoanArea = (
        <div>
          <div className="addanother-box">
            <button
              className="btn btn-link"
              onClick={(e) => {
                handleAddPrivateLoan(e);
              }}
            >
              + Got Private Loans?
            </button>
          </div>
        </div>
      );
    } else {
      privLoanArea = (
        <div>
          {loans
            .filter((rowData) => rowData.federal === false)
            .map(renderLoanRow)}
          <div className="addanother-box">
            <button
              className="btn btn-link"
              onClick={(e) => {
                handleAddPrivateLoan(e);
              }}
            >
              + Add Another Loan
            </button>
          </div>
        </div>
      );
    }
    return (
      <div>
        <h3>Calculate your savings with Anvil</h3>
        <h4>Federal Loans</h4>
        {loans.filter((rowData) => rowData.federal === true).map(renderLoanRow)}
        <div className="addanother-box">
          <button
            className="btn btn-link"
            onClick={(e) => {
              handleAddFederalLoan(e);
            }}
          >
            + Add Another Loan
          </button>
        </div>
        <h4>Private Loans</h4>
        {privLoanArea}
        <div className="total-container">
          <div>
            <span className="label-style">Loan Total: </span>
            {/* <span> ${getLoanTotal()} </span> */}
            <CurrencyFormat
              value={getLoanTotal()}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
            />
          </div>
          <button
            onClick={handleNavigateToIndex(screenIndex + 1)}
            className="btn btn-primary"
          >
            Get My Estimate
          </button>
        </div>
      </div>
    );
  }

  function convertTimespanToFutureDate(monthsFromToday) {
    let futureDate = moment().add(monthsFromToday, "months");
    return futureDate.format("MMMM YYYY");
  }

  function generateSignupMethod() {
    if (smsEligible) {
      return (
        <div className="form-inline">
          You are also eligible for our alpha-testing program - sign up today!
          <div className="input-group">
            <input
              type="string"
              placeholder="Enter your phone number..."
              {...bindPhoneNumber}
            />
          </div>
          <button type="submit" className="btn">
            Sign Me Up!
          </button>
        </div>
      );
    } else {
      return (
        <div className="form-inline">
          <div className="input-group">
            <input
              type="string"
              placeholder="Enter your email..."
              {...bindEmail}
            />
          </div>
          <button onClick={handleNavigateToIndex(3)} className="btn">
            Email Me!
          </button>
        </div>
      );
    }
  }

  function generateResultScreen() {
    return (
      <div className="result-box">
        <div className="logo-container">
          <img src={StandardLogo} alt="colored logo img" />
        </div>
        <div className="main-container">
          <div className="text-center">
            <h5>Based on your information, Anvil can save you</h5>
            <h5>
              <CurrencyFormat
                value={(
                  savingsEstimateData["non-anvil"].total -
                  savingsEstimateData.anvil.total
                ).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </h5>
            <div>
              <h5>and get you out of debt by</h5>
            </div>
            <h5>
              {convertTimespanToFutureDate(savingsEstimateData.anvil.time)}
            </h5>
            <h5>
              <i>
                {(
                  (savingsEstimateData["non-anvil"].time -
                    savingsEstimateData.anvil.time) /
                  12
                ).toFixed(2)}{" "}
                years faster!
              </i>
            </h5>
          </div>
          {generateSignupMethod()}
        </div>
        <button
          onClick={handleNavigateToIndex(screenIndex - 1)}
          className="btn back-btn"
        >
          Back
        </button>
      </div>
    );
  }

  let submitHandler = null;
  switch (screenIndex) {
    case 2:
      submitHandler = handleSMSRegisterSubmit;
      break;
    default:
      submitHandler = handleSubmit;
      break;
  }

  return (
    <div>
      <div className="row justify-content-center">
        <div className="col-12 col-xl-10">
          <form onSubmit={submitHandler}>
            <div className="getsaving-box">{generateCalculatorInputBox()}</div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
