import React from "react";
import "./value-prop.css";
import Screenshot from "../assets/phone.png";
import { BsFillLightningFill, BsHash } from "react-icons/bs";
import { BiDollar } from "react-icons/bi";

const ValueProposition = () => {
  return (
    <div className="valueprop-container">
      <div className="row">
        <div className="column">
          <div className="valueprop-header">
            <h1>Anvil makes it simple.</h1>
          </div>
          <div>
            <p className="valueprop-content">
              At Anvil, we love saving money, we love efficiency, and we love
              solving hard problems.
            </p>
            <p className="valueprop-content">
              (in short - we're nerds, and we're okay with that.)
            </p>
            <p className="valueprop-content">
              We're so serious about saving, we couldn't stand the idea of
              throwing money away on unnecessary student loan payments - so we
              built Anvil, the student loan management platform that optimizes
              your savings.
            </p>
            <p className="valueprop-content">
              Just connect your loans, and Anvil will optimize your loan
              structure, repayment plan, payment distribution and contribution
              strategy. Almost everything is automated - sit back, relax and
              watch the savings stack up.
            </p>
            <p className="valueprop-content">
              Loans aren't just a math problem - they're a human problem too.
              Financial fitness is just like normal fitness - it takes
              consistency and encouragement. Anvil coaches you, breaks down the
              numbers and makes it as easy as possible to maximize your
              repayment progress.
            </p>
            <p className="valueprop-content">
              It's a good day. Let's crush your student debt together.
            </p>
          </div>
        </div>
        <div className="column">
          <div className="value-facts-list">
            <div className="facts-item">
              <div className="facts-item-left-content">
                <div className="facts-item-icon-container">
                  <BsHash style={{ width: 50, height: 50 }} />
                </div>
              </div>
              <div className="facts-item-right-content">
                <h3>Simple</h3>
                <p>
                  It takes less than 5 minutes to connect your student loans and
                  complete your financial profile with Anvil.
                </p>
              </div>
            </div>
            <div className="facts-item">
              <div className="facts-item-left-content">
                <div className="facts-item-icon-container">
                  <BsFillLightningFill style={{ width: 50, height: 50 }} />
                </div>
              </div>
              <div className="facts-item-right-content">
                <h3>Powerful</h3>
                <p>
                  There are dozens of approaches for repaying debt. Anvil
                  simulates your debt journey under as many possible strategies
                  as we can. In many situations, we're able to recommend an
                  absolute "best" strategy. In others, we can get it down to one
                  or two, with the ties broken by your personal preference.
                </p>
              </div>
            </div>
            <div className="facts-item">
              <div className="facts-item-left-content">
                <div className="facts-item-icon-container">
                  <BiDollar style={{ width: 50, height: 50 }} />
                </div>
              </div>
              <div className="facts-item-right-content">
                <h3>Rewarding</h3>
                <p>
                  The average user with Anvil is running a financial strategy
                  that reduces their total debt payments by $14K and their time
                  in debt by 6 years.
                </p>
                <p>
                  As if that weren't enough, Anvil rewards you for meeting your
                  savings goals and improving your financial health!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ValueProposition;
