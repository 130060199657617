import React from "react";
import "./calculator.css";
import Home from "./Saving/Saving";

const Calculator = () => {
  return (
    <div className="calculator-container">
      <Home />
    </div>
  );
};

export default Calculator;
