import React from "react";
import "./extra.css";
import TreeDiagram from "../assets/tree-diagram.png";

const Extra = () => {
  return (
    <div className="extra-container">
      <div className="row">
        <div className="column">
          <div className="centered">
            <img className="inner-container-left-img" src={TreeDiagram} />
          </div>
        </div>
        <div className="double-column">
          <div className="extra-text-container">
            <h1>Leverage the power of technology.</h1>
            <p>
              Anvil's powerful financial computing engine crunches the numbers
              to identify the best student loan management strategies for you,
              and flags opportunities for you to save money. With Anvil, you
              won't leave anything on the table.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Extra;
