import React from "react";
import "./header.css";
import AllWhiteLogo from "../assets/AllWhiteLogo.png";
import { Link } from "react-router-dom";
const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/home" className="image">
          <img
            src={AllWhiteLogo}
            className="logo"
            alt="AllWhiteLogo in header"
          />
        </Link>
      </div>
    </header>
  );
};

export default Header;
