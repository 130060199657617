import logo from "./logo.svg";
import "./App.css";
import React, { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  Link,
} from "react-router-dom";
import Header from "./External/header";
import Hero from "./External/hero";
import Calculator from "./External/calculator";
import LandingPagePreview from "./assets/landing.png";
import ValueProposition from "./External/value-prop";
import Footer from "./External/footer";
import Extra from "./External/extra";
import JoinBeta from "./External/join-beta";

import { ExternalScreenStateContext } from "./External/contexts";

function App() {
  return (
    <div className="App">
      <div className="thumbnail-hidden">
        <img className="thumbnail-hidden-img" src={LandingPagePreview} alt="" />
      </div>
      <Router>
        <div className="page-container">
          <Header />

          <div className="content-wrap">
            <Switch>
              <Route path="/home">
                <Fragment>
                  <Hero />
                  <ValueProposition />
                  <Extra />
                  {/* <TryCalculator /> */}
                </Fragment>
              </Route>
              <Route path="/calculator">
                <Calculator />
              </Route>
              <Route path="/beta">
                <JoinBeta />
              </Route>
              <Route path="*">
                <Redirect to="/home" />
              </Route>
            </Switch>
          </div>
          <div className="footer-section">
            <Footer />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
