import React from "react";
import "./join-beta.css";
import Logo from "../assets/StandardLogo.png";

const JoinBeta = () => {
  return (
    <div className="join-beta-container">
      <div className="centered">
        <h1>At Capacity! </h1>
        <p>
          We can't get you onto our app today, but enter your email and you'll
          be notified the minute space opens up.
        </p>
        {/* The following content is from MailChimp and should not be edited beyond styling. */}
        <form
          action="https://anviltech.us3.list-manage.com/subscribe/post?u=f3bc39011772722c75228ce8b&amp;id=9281daf969"
          method="post"
          name="mc-embedded-subscribe-form"
          className="validate"
          style={{ width: "100%", textAlign: "center" }}
          target="_blank"
          noValidate
        >
          <div className="email-input">
            <input
              type="email"
              name="EMAIL"
              className="form-control"
              id="mce-EMAIL"
              placeholder="Enter Your Email Address..."
            />
          </div>
          <button type="submit" className="try-it-button" id="inputPassword2">
            Subscribe
          </button>
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>
          <div aria-hidden="true">
            <input
              type="text"
              name="b_f3bc39011772722c75228ce8b_9281daf969"
              style={{ display: "none" }}
              tabIndex="-1"
              defaultValue=""
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default JoinBeta;
