import React from "react";
import "./hero.css";
import Restr from "../assets/restr.png";
import { Link, useHistory } from "react-router-dom";
const Hero = () => {
  const history = useHistory();

  const handleGetStarted = () => {
    console.log("Getting started");
    history.push("/beta");
  };

  return (
    <div className="hero-container">
      <div className="hero-inner">
        <div className="img-container" style={{ width: "100%" }}>
          <img src={Restr} style={{ width: "100%" }} />
        </div>
      </div>
      <div className="button-container">
        <button className="get-started-btn" onClick={handleGetStarted}>
          Join Now
        </button>
      </div>
    </div>
  );
};

export default Hero;
