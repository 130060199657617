import React from "react";
import "./footer.css";
import Home from "./Saving/Saving";
import Logo from "../assets/AllWhiteLogo.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-top-row-container">
        <div className="icon-container">
          <img className="icon-img" src={Logo} />
        </div>
        <div className="links-container">
          {/*
                <div className="link-item">
                    Privacy Policy
                </div>
                */}
        </div>
      </div>
      <div className="hr-container">
        <hr />
      </div>
      <div className="disclosures-container">
        <p>Copyright Anvil Tech, 2021. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
